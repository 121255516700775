
import { reactive, ref } from "vue";
import { storesManageService } from "@/service";
import { useRoute, useRouter } from "vue-router";
import moment from "moment";
export default {
  setup() {
    const router = useRouter();
    const route = useRoute();

    const delayDate = reactive({
      show: false,
      label: "",
      value: new Date(),
      minDate: moment(Number(route.query.minDate as string)).toDate(),
      maxDate: moment(Number(route.query.maxDate as string)).toDate(),
      onConfirm(value: Date) {
        delayDate.label = moment(value).format("YYYY-MM-DD HH:mm");
        delayDate.show = false;
      }
    });

    const reasons = reactive({
      show: false,
      label: "",
      value: "",
      actions: [],
      async getOcDelayReasons() {
        const { data } = await storesManageService.ocDelayReasons();
        reasons.actions = data.data;
      },
      select(value: any) {
        reasons.label = value.name;
        reasons.value = value.id;
      }
    });

    reasons.getOcDelayReasons();

    const formData = reactive({
      desc: ""
    });

    const submit = async () => {
      try {
        const { data } = await storesManageService.storesDelay({
          stores_id: route.query.stores_id,
          oc_type: route.query.oc_type,
          delay_date: moment(delayDate.value).format("YYYY-MM-DD HH:mm"),
          delay_reason_id: reasons.value,
          desc: formData.desc
        });
        router.back()
      } catch (error) {}
    };

    return {
      delayDate,
      reasons,
      formData,
      submit
    };
  }
};
